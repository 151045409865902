import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import AnimationContainer from 'components/animation-container'
import AnimatedHeading from 'components/animated-heading'
import "./styles.scss"
import { divide } from 'lodash'

class ServicesOne extends React.Component {


    shouldComponentUpdate() {
        return false
    }


    render() {
        const Section = styled.section`
            position: relative;
            overflow: hidden;
            background-color: #111;
            background-image: url(${this.props.background.childImageSharp.fluid.src});
            background-size: cover;
            .heading {
                width: 100%;
            }
          }
        `

        const ServiceContainer = styled.div`
            background-color: rgba(0,0,0,.9);
            padding: 100px 0;
        `


        const ServiceElement = styled.div`
            margin-bottom: 20px;
            text-align: center;
            padding: 30px;
            border-radius: 20px;
            transition: .2s;
            &:hover {
                background-color: #000;
                box-shadow: 0 28px 60px rgb(4, 229, 229, .1);
                transform: translateY(-10px);
            }
        `
        //  const ServiceHeading = styled.h4`
        //     font-size: 30px;
        //     font-weight: 500;
        //     font-family: Teko;
        //     color: #fff;
        // `
        const ServiceSeparator = styled.div`
            height: 5px;
            width: 50px;
            background-color: #04e5e5;
            margin-bottom: 10px;
            margin: auto;
         `
        const ServiceIcon = styled.div`
            margin-bottom: 20px;
            img {
                max-height: 70px;
                max-width: 80%;
            }
        `

        const ServiceList = styled.ul`
            padding: 0;
            margin: 11px 0 0 0;
        `

        const ServiceListElement = styled.li`
            list-style: none;
            color: #c5c5c5;
            font-weight: 300;
            font-size: 14px;
            margin: 5px 0px;
            &::before {
                display: inline-block;
                content: '';
                border-radius: 2.5px;
                height: 5px;
                width: 5px;
                margin-right: 10px;
                background-color:#04e5e5;
                position: relative;
                bottom: 2.5px;
            }
        `

        return(
            <Section id="Information">
                <ServiceContainer>
                    <Container>
                        <AnimatedHeading text="Information" />
                        <Row>
                            <Col md={4} >
                                <AnimationContainer animation="fadeInLeft" delay={200}>
                                        <ServiceElement onClick={() => window.open('http://paypal.me/squadquebec')}>
                                            <ServiceIcon>
                                                    <img src={this.props.paypalIcon.childImageSharp.fluid.src} alt="Paypal" />
                                            </ServiceIcon>
                                            <ServiceSeparator/>
                                            <ServiceList>
                                                <ServiceListElement>
                                                    Squad Whitelist CAD 4$/Mois
                                                </ServiceListElement>
                                                <ServiceListElement>
                                                    Permet au serveur Squad Québec d'exister
                                                </ServiceListElement>
                                                <ServiceListElement>
                                                    Non obligatoire
                                                </ServiceListElement>
                                            </ServiceList>
                                        </ServiceElement>
                                </AnimationContainer>
                            </Col>
                            <Col md={4}>
                                <AnimationContainer animation="fadeInRight" delay={600}>
                                    <ServiceElement onClick={() => window.open('https://www.facebook.com/SquadQuebec')}>
                                        <ServiceIcon>
                                                <img src={this.props.facebookIcon.childImageSharp.fluid.src} alt="Facebook" />
                                        </ServiceIcon>
                                        <ServiceSeparator/>
                                        <ServiceList>
                                            <ServiceListElement>
                                                Suivez-nous sur facebook
                                            </ServiceListElement>
                                        </ServiceList>
                                    </ServiceElement>
                                </AnimationContainer>
                            </Col>
                            <Col md={4}>
                                <AnimationContainer animation="fadeInLeft" delay={800}>
                                    <ServiceElement onClick={() => window.open('https://www.patreon.com/squadquebec')}>
                                        <ServiceIcon>
                                                <img src={this.props.patreonIcon.childImageSharp.fluid.src} alt="Patreon" />
                                        </ServiceIcon>
                                        <ServiceSeparator/>
                                        <ServiceList>
                                            <ServiceListElement>
                                                Supporte nous de façons mensuels
                                            </ServiceListElement>
                                            <ServiceListElement>
                                                Permet au serveur Squad Québec d'exister
                                            </ServiceListElement>
                                        </ServiceList>
                                    </ServiceElement>
                                </AnimationContainer>
                            </Col>
                            <Col md={4}>
                                <AnimationContainer animation="fadeInUp" delay={1000}>
                                    <ServiceElement>
                                        <ServiceIcon>
                                                <img src={this.props.squadIcon.childImageSharp.fluid.src} alt="Squad" />
                                        </ServiceIcon>
                                        <ServiceSeparator/>
                                        <ServiceList>
                                            <ServiceListElement>
                                                Squad est un FPS tactique qui offre des expériences de combat authentiques grâce au travail d'équipe, à une communication constante et à un gameplay. Il comble le grand écart entre le jeu de tir d'arcade et la simulation militaire avec des batailles de 100 joueurs, des combats interarmes, la construction de bases et un excellent système VoIP intégré
                                            </ServiceListElement>
                                        </ServiceList>
                                    </ServiceElement>
                                </AnimationContainer>
                            </Col>
                            <Col md={4}>
                                <AnimationContainer animation="fadeInUp" delay={1000}>
                                    <ServiceElement onClick={() => window.open('https://www.tiktok.com/@squadquebec.ca')}>
                                        <ServiceIcon>
                                            <img src={this.props.tiktokIcon.childImageSharp.fluid.src} alt="Tiktok" />
                                        </ServiceIcon>
                                        <ServiceSeparator/>
                                        <ServiceList>
                                            <ServiceListElement>
                                                Viens voir nos Tiktok !!
                                            </ServiceListElement>
                                        </ServiceList>
                                    </ServiceElement>
                                </AnimationContainer>
                            </Col>
                        </Row>
                    </Container>
                </ServiceContainer>
            </Section>
        )
    }

}

export default props => (
    <StaticQuery
      query={graphql`
      query {
        background: file(relativePath: {eq: "bg2.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 4000, quality: 100) {
              src
            }
          }
        }
        paypalIcon: file(relativePath: {eq: "icons/paypal.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        facebookIcon: file(relativePath: {eq: "icons/facebook.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        patreonIcon: file(relativePath: {eq: "icons/patreon.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        squadIcon: file(relativePath: {eq: "icons/squad.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        tiktokIcon: file(relativePath: {eq: "icons/tiktok.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        mobile: file(relativePath: {eq: "icons/mobile.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        seo: file(relativePath: {eq: "icons/seo2.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        email: file(relativePath: {eq: "icons/email.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        ui: file(relativePath: {eq: "icons/sketch.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        network: file(relativePath: {eq: "icons/network.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
      }
      `}
      render={({
        background,
        mobile,
        seo,
        facebookIcon,
        patreonIcon,
        email,
        ui,
        squadIcon,
        paypalIcon,
        tiktokIcon,
        network}) => <ServicesOne
        background={background}
        patreonIcon={patreonIcon}
        paypalIcon={paypalIcon}
        squadIcon={squadIcon}
        tiktokIcon={tiktokIcon}
        facebookIcon={facebookIcon}
        mobile={mobile}
        seo={seo}
        email={email}
        ui={ui}
        network={network}
        {...props} />}
    />
  )
